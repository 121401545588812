import React from "react";
import Switch from "@material-ui/core/Switch";
import styled from "styled-components";
import { AdditionalQuestionsPromptType } from "../types";
import Form from "react-bootstrap/esm/Form";

const FormGroupStyled = styled(Form.Group)`
  display: flex;
  justify-content: space-between;
`;

const AdditionalQuestionsPrompt = (props: AdditionalQuestionsPromptType) => {
  const { secondaryPrompt, showSecondaryQuestions, setShowSecondaryQuestions } =
    props;

  return (
    <FormGroupStyled controlId="secondaryPrompt">
      <Form.Label>{secondaryPrompt}</Form.Label>
      <Switch
        checked={showSecondaryQuestions}
        onChange={() => setShowSecondaryQuestions(!showSecondaryQuestions)}
        color="primary"
      />
    </FormGroupStyled>
  );
};

export default AdditionalQuestionsPrompt;
