import React from "react";
import styled from "styled-components";

const MainDivImg = styled.div`
  background-image: url("mainPhotoCropped.png");
  width: 100%;
  height: 60vh;
  min-height 300px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const MainSection = () => {
  return <MainDivImg />;
};
