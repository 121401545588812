import { QuestionaireType } from "../components/Questionaire/types";

export const questions: QuestionaireType = {
  questionsMain: [
    {
      displayValue: "Your Name",
      value: "",
      id: "name",
      hint: "",
      required: true,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "Please add your name.",
    },
    {
      displayValue: "Email",
      value: "",
      id: "email",
      hint: "",
      required: true,
      type: "email",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "Please add a valid email.",
    },
    {
      displayValue: "Phone Number",
      value: "",
      id: "number",
      hint: "",
      required: true,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "Please put a valid phone number with an area code.",
    },
    {
      displayValue: "Date of Wedding",
      value: "",
      id: "date",
      hint: "",
      required: true,
      type: "date",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "Please put a date for the wedding.",
    },
    {
      displayValue: "Number of Guests",
      value: "",
      id: "guestCount",
      hint: "",
      required: true,
      type: "number",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "Please put the number of guests.",
    },
    {
      displayValue: "Ceremony Location",
      value: "",
      id: "location",
      hint: "",
      required: true,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "Please add a ceremony location.",
    },
    {
      displayValue: "Reception Location",
      value: "",
      id: "receptionLocation",
      hint: "If different than ceremony location",
      required: false,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "",
    },
    {
      displayValue: "Number of attendents in wedding party?",
      value: "",
      id: "attendentCount",
      hint: "(Bridesmaids, groomsmen, etc.)",
      required: true,
      type: "number",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "Please add the number of people in the wedding party.",
    },
    {
      displayValue: "What is your overall budget?",
      value: "",
      id: "overallBudget",
      hint: "",
      required: false,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "",
    },
    {
      displayValue: "What is your estimated floral budget?",
      value: "",
      id: "floralBudget",
      hint: "(10% - 20% is suggested depending floral style)",
      required: true,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "Please provide an estimated floral budget.",
    },
    {
      displayValue: "When would you like to schedule a consultation?",
      value: "",
      id: "consultationDate",
      hint: "",
      required: true,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid:
        "Please provide when you would like to schedule a consultation.",
    },
    {
      displayValue: "Do you have a Pinterest board with your wedding ideas?",
      value: "",
      id: "pinterestBoard",
      hint: "Please share private boards with bloom@jmfloralartstudio.com",
      required: false,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "",
    },
    {
      displayValue: "How did you hear about us?",
      value: "",
      id: "referral",
      hint: "(Referral, social media, venue, search, etc.)",
      required: false,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "",
    },
  ],
  questionsSecondary: [
    {
      displayValue: "How would you like your wedding day to feel?",
      value: "",
      id: "feeling",
      hint: "",
      required: false,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "",
    },
    {
      displayValue:
        "Describe you color palette, style, and any important details you would like to share",
      value: "",
      id: "importantDetails",
      hint: "",
      required: false,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "",
    },
    {
      displayValue: "What is the most important part of your wedding day?",
      value: "",
      id: "weddingDayHighlight",
      hint: "",
      required: false,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "",
    },
    {
      displayValue:
        "Any additional questions or details you would like to share?",
      value: "",
      id: "additionalDetails",
      hint: "",
      required: false,
      type: "string",
      placeholder: "",
      invalid: false,
      feedbackInvalid: "",
    },
  ],
  secondaryPrompt: "Any additional information you want to provide?",
};
