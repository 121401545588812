import React from "react";
import Form from "react-bootstrap/Form";
import { FormLabel } from "../FormLabel";
import FormText from "../FormText";
import { FormGroupType } from "./types";

const FormGroup = ({
  value,
  id,
  displayValue,
  hint,
  required,
  type,
  placeholder,
  onChangeEvent,
  feedbackInvalid,
  invalid,
}: FormGroupType) => {
  return (
    <Form.Group controlId={id}>
      <FormLabel
        displayValue={displayValue}
        hint={hint}
        required={required}
        invalid={invalid}
      />
      <FormText className="text-muted">{hint}</FormText>
      <Form.Control
        value={value}
        type={type}
        placeholder={placeholder}
        required={required}
        onChange={(evnt) => onChangeEvent(evnt)}
        isInvalid={feedbackInvalid !== "" && invalid}
      />
      <Form.Control.Feedback type="invalid">
        {feedbackInvalid}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormGroup;
