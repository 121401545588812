import styled from "styled-components";
import Form from "react-bootstrap/esm/Form";
import { FormGroupProps } from "./types";
import { RequiredComponent } from "./RequiredComponent";

const FormLabelDefault = styled(Form.Label)`
  margin: 0px 0px 0.5rem 0px;
`;
const FormLabelWithHint = styled(Form.Label)`
  margin: 0px 0px 0px 0px;
`;

export const FormLabel = ({
  displayValue,
  required,
  hint,
  invalid,
}: FormGroupProps) => {
  return (
    <>
      {hint ? (
        <FormLabelWithHint>
          {displayValue}
          <RequiredComponent invalid={invalid} required={required} />
        </FormLabelWithHint>
      ) : (
        <FormLabelDefault>
          {displayValue}
          <RequiredComponent invalid={invalid} required={required} />
        </FormLabelDefault>
      )}
    </>
  );
};
