import React, { useState } from "react";
import NavbarOriginal from "./components/Navbar";
import Questionaire from "./components/Questionaire";
import "./App.scss";
import jm_logo from "./jm_logo.png";
import { questions } from "./data/questionaire";
import links from "./data/links";
import { quote } from "./data/quote";
import { Quote } from "./components/Quote";
import { MainSection } from "./components/MainSection";
import { ContactButton } from "./components/ContactButton";
import { AboutMeSection } from "./components/AboutMeSection";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

function App() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);
  const [openFailedServer, setOpenFailedServer] = useState(false);
  const toasterFuctions = {
    success: setOpenSuccess,
    failed: setOpenFailed,
    serverFailed: setOpenFailedServer,
  };

  function handleCloseSuccess(
    event: React.SyntheticEvent<Element, Event>,
    reason?: string
  ) {
    if (reason && reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  }

  function handleCloseFailed(
    event: React.SyntheticEvent<Element, Event>,
    reason?: string
  ) {
    if (reason && reason === "clickaway") {
      return;
    }

    setOpenFailed(false);
  }

  function handleCloseFailedServer(
    event: React.SyntheticEvent<Element, Event>,
    reason?: string
  ) {
    if (reason && reason === "clickaway") {
      return;
    }

    setOpenFailedServer(false);
  }

  const logoImg = (
    <img src={jm_logo} className="navbar-logo" alt="JM Floral Art Studio" />
  );

  return (
    <div className="navbar-spacer">
      <NavbarOriginal logo={logoImg} navbarClasses={[""]} links={links} />
      <MainSection />
      <Quote displayValue={quote.displayValue} value={quote.value} />
      <ContactButton />
      <AboutMeSection />
      <Questionaire questions={questions} toasterFuctions={toasterFuctions} />
      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          Thank you! We will contact you shortly.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFailed}
        autoHideDuration={3000}
        onClose={handleCloseFailed}
      >
        <Alert onClose={handleCloseFailed} severity="error">
          Please fill out the required information in the form.
        </Alert>
      </Snackbar>

      <Snackbar
        open={openFailedServer}
        autoHideDuration={7000}
        onClose={handleCloseFailedServer}
      >
        <Alert onClose={handleCloseFailedServer} severity="error">
          Something went wrong on the server. Please email us directly at:
          <a href="mailto:bloom@jmfloralartstudio.com">
            bloom@jmfloralartstudio.com
          </a>
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
