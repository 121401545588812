import React from "react";
import FormGroup from "../FormGroup";
import { QuestionairePassedThroughProps, QuestionType } from "../types";

const QuestionsMain = (props: QuestionairePassedThroughProps) => {
  const { questionsMain, setQuestionsMain } = props;

  return (
    <>
      {questionsMain &&
        questionsMain.map(
          ({
            displayValue,
            value,
            id,
            hint,
            required,
            type,
            placeholder,
            feedbackInvalid,
            invalid,
          }: QuestionType) => {
            return (
              <FormGroup
                key={id}
                value={value}
                id={id}
                displayValue={displayValue}
                hint={hint}
                required={required}
                type={type}
                placeholder={placeholder}
                feedbackInvalid={feedbackInvalid}
                invalid={invalid}
                onChangeEvent={(evnt: any) => {
                  const { value } = evnt.target;
                  const questionsWithValue: QuestionType[] = questionsMain.map(
                    (question) => {
                      const { id: qId } = question;
                      const sameQuestion = qId === id;
                      if (sameQuestion) {
                        question.value = value;
                        question.invalid = value === "";
                      }

                      return question;
                    }
                  );
                  setQuestionsMain(questionsWithValue);
                }}
              />
            );
          }
        )}
    </>
  );
};

export default QuestionsMain;
