import React, { ReactNode } from "react";

import Navbar from "react-bootstrap/Navbar";

interface NavbarProps {
  navbarClasses: Array<String>;
  logo: ReactNode;
  links: Array<Links>;
  children?: ReactNode;
}

interface Links {
  href?: string;
  src?: string;
  element?: ReactNode | any;
  displayValue?: string;
}

const NavbarOriginal = (props: NavbarProps) => {
  const { navbarClasses, links, logo } = props;

  return (
    <Navbar
      id="navbar"
      bg="light"
      className={navbarClasses.join(" ")}
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
      }}
    >
      <Navbar.Brand href="#">{logo}</Navbar.Brand>
      <div style={{ display: "flex", flexWrap: "nowrap" }}>
        {links.map((link) => {
          const { href, src, displayValue, element } = link;
          const linkElement = src ? (
            <img src={src} alt={displayValue} />
          ) : (
            displayValue
          );
          return <div key={href}>{element || linkElement}</div>;
        })}
      </div>
    </Navbar>
  );
};

export default NavbarOriginal;
