import { QuestionType } from "./types";

export const validateDate = (testdate: string) => {
  const regex = new RegExp(/[0-9]{2,4}[\W\D]{1}[0-9]{2}[\W\D]{1}[0-9]{2,4}/);
  const valid = regex.test(testdate);
  return valid;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  const checkNumber: string = phoneNumber || "";
  const valid = (checkNumber.match(/\d/g) || "").length > 9;
  return valid;
};

export const validateEmail = (email: string) => {
  const checkEmail: string = email || "";
  const valid =
    checkEmail && checkEmail.indexOf("@") > -1 && checkEmail.indexOf(".") > -1;
  return valid;
};

export const generateEmailMessage = (
  main: Array<QuestionType>,
  secondary: Array<QuestionType>
) => {
  let message = "";
  const lineBreak = "<br/>";

  main.forEach((msg: QuestionType) => {
    const { displayValue, value } = msg;
    message += `${displayValue}${lineBreak}<b>${value}</b>${lineBreak}${lineBreak}`;
  });

  secondary.forEach((msg: QuestionType) => {
    const { displayValue, value } = msg;
    message += `${displayValue}${lineBreak}<b>${value}</b>${lineBreak}${lineBreak}`;
  });

  return message;
};
