import React from "react";
import { RequiredComponentProps } from "../types";

export const RequiredComponent = ({
  required,
  invalid,
}: RequiredComponentProps) => {
  const className = invalid ? "text-required" : "";
  return <>{required ? <span className={className}>*</span> : ""}</>;
};
