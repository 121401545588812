import { SocialIcon } from "react-social-icons";

const iconSize = 30;

const iconColor = "#444444";

const linksHref = [
  "https://www.instagram.com/jmfloralartstudio/",
  "https://www.facebook.com/jmfloralartstudio/",
  "email:bloom@jmfloralartstudio.com",
];

const links = linksHref.map((href) => ({
  href,
  element: (
    <SocialIcon
      className="social-link"
      url={href}
      bgColor={iconColor}
      style={{ height: iconSize, width: iconSize }}
    />
  ),
}));

export default links;
