import React from "react";
import styled from "styled-components";

const AboutMeSectionWrapper = styled.div`
  margin: auto !important;
  background-image: url("bouquetsEdited.jpg");
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  & div {
    padding: 50px 30px !important;
    max-width: 800px;
    margin: auto !important;
  }
  & h3 {
  }
  & p {
  }
`;

export const AboutMeSection = () => {
  return (
    <AboutMeSectionWrapper>
      <div>
        <h3>Meet Jeannie</h3>
        <p>
          Flowers, with their uniqueness and beauty, have fascinated me since I
          was little girl. I was born in Southern California, and my family
          always enjoyed a close to nature lifestyle.
        </p>
        <p>
          In the 80’s I was introduced to the outstanding floral designer Rene
          Van Rems through floral design classes at UCSD. He shared his
          knowledge/technique of different styles such as Ikebana, Biedermeier,
          and European designs.
        </p>
        <p>
          A natural choice for me was a degree in Ornamental Horticulture,
          followed by instruction in Floral Design. I learned the elements of
          style and the mechanics of the floral industry.
        </p>
        <p>
          The opportunity to share such a pivotal moment in a couple’s wedding
          is extremely rewarding. I can’t wait to work with you and bring your
          floral vision to life.
        </p>
      </div>
    </AboutMeSectionWrapper>
  );
};
