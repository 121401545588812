import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

const ContactButtonWrapper = styled.div`
  margin: auto;
  padding: 30px auto;
  display: flex;
  justify-content: center;
  background-color: #7c719c;
`;

const ContactBtn = styled(Button)`
  background-color: #fefefe !important;
  margin: 50px auto !important;
  padding: 15px 60px !important;
  text-transform: inherit !important;
  &:hover {
    background-color: #e0e0e0 !important;
  }
  & span {
    text-transform: inherit !important;
  }
`;

export const ContactButton = () => {
  return (
    <ContactButtonWrapper>
      <ContactBtn
        onClick={(e: any) => {
          const contactElem = document.getElementById("contact-section");
          const navbarElem = document.getElementById("navbar");
          if (!contactElem || !navbarElem) return;
          const contactElemBounds = contactElem.getBoundingClientRect();
          const navbarElemBounds = navbarElem.getBoundingClientRect();
          const scrollPosition =
            contactElemBounds.top + window.scrollY - navbarElemBounds.height;
          window.scroll(0, scrollPosition);
        }}
      >
        Contact Me
      </ContactBtn>
    </ContactButtonWrapper>
  );
};
