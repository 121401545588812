import React from "react";
import styled from "styled-components";
import { QuoteProps } from "./types";

const QuoteBody = styled.div`
  background-color: #e6e6e6;
`;

const QuoteWrapper = styled.div`
  text-align: center;
  display: block;
  padding: 50px 30px;
  & p {
    display: block;
    &.quote {
      font-family: "Italianno", cursive;
      font-size: 2rem;
    }
    &.quote-author {
      margin-bottom: 0px;
    }
  }
`;

export const Quote = ({ displayValue, value }: QuoteProps) => {
  return (
    <QuoteBody>
      <QuoteWrapper className="body-secondary">
        <p className="quote">{displayValue}</p>
        <p className="quote-author">{value}</p>
      </QuoteWrapper>
    </QuoteBody>
  );
};
