import React, { useState } from "react";
import emailjs, { init as emailjsInit } from "emailjs-com";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { QuestionaireProps, QuestionType } from "./types";
import QuestionsMain from "./QuestionsMain";
import QuestionsSecondary from "./QuestionsSecondary";
import AdditionalQuestionsPrompt from "./AdditionalQuestionsPrompt";
import {
  generateEmailMessage,
  validateDate,
  validateEmail,
  validatePhoneNumber,
} from "./utils";

const QuestionaireWrapper = styled.div`
  background-color: #7c719c;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
  @media (min-width: 768px) {
    max-width: 200px;
  }
`;

const ButtonWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
`;

const QuestionaireTitle = styled.h3``;

emailjsInit("user_ZnWNzkF6Kq0etr5LMxpbt");

const Questionaire = ({
  questions: { questionsMain, questionsSecondary, secondaryPrompt },
  toasterFuctions,
}: QuestionaireProps) => {
  const [mainQuestions, setQuestionsMain] =
    useState<QuestionType[]>(questionsMain);
  const { success, failed, serverFailed } = toasterFuctions;

  const [secondaryQuestions, setQuestionsSecondary] =
    useState<QuestionType[]>(questionsSecondary);

  const [showSecondaryQuestions, setShowSecondaryQuestions] =
    useState<boolean>(false);

  return (
    <QuestionaireWrapper id="contact-section">
      <Form className="body">
        <QuestionaireTitle>Start the Conversation</QuestionaireTitle>
        <QuestionsMain
          questionsMain={mainQuestions}
          setQuestionsMain={setQuestionsMain}
        />
        <AdditionalQuestionsPrompt
          secondaryPrompt={secondaryPrompt}
          showSecondaryQuestions={showSecondaryQuestions}
          setShowSecondaryQuestions={setShowSecondaryQuestions}
        />
        {showSecondaryQuestions ? (
          <QuestionsSecondary
            questionsSecondary={secondaryQuestions}
            setQuestionsSecondary={setQuestionsSecondary}
          />
        ) : (
          ""
        )}
        <ButtonWrapper>
          <ButtonStyled
            variant="primary"
            type="submit"
            onClick={(event: Event) => {
              event.preventDefault();
              let validEmail = true;
              let name = "";
              let email = "";

              const mQ = mainQuestions.map((question) => {
                const { value, required, type, id } = question;
                const invalidResponse = required && value === "";
                question.invalid = invalidResponse;

                if (type === "email") {
                  email = value;
                  question.invalid = validateEmail(value) === false;
                  validEmail = !question.invalid;
                }

                if (type === "name") {
                  name = value;
                }

                if (type === "date") {
                  question.invalid = validateDate(value) === false;
                  validEmail = !question.invalid;
                }

                if (id === "number") {
                  question.invalid = validatePhoneNumber(value) === false;
                  validEmail = !question.invalid;
                }

                return question;
              });
              const sQ = secondaryQuestions.map((question) => {
                const { value, required } = question;
                question.invalid = required && value === "";
                if (question.invalid) {
                  validEmail = false;
                }
                return question;
              });
              setQuestionsMain(mQ);
              setQuestionsSecondary(sQ);

              if (!validEmail) {
                failed(true);
                return;
              }

              const templateParams = {
                from_name: name,
                reply_to: email,
                to_name: "Jeannie",
                message: generateEmailMessage(
                  mainQuestions,
                  secondaryQuestions
                ),
              };

              emailjs
                .send(
                  "gmail_test_54321",
                  "basic_email_template_619",
                  templateParams
                )
                .then(
                  function (response) {
                    success(true);
                  },
                  function (err) {
                    serverFailed(true);
                  }
                );
            }}
          >
            Send
          </ButtonStyled>
        </ButtonWrapper>
      </Form>
    </QuestionaireWrapper>
  );
};

export default Questionaire;
