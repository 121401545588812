import React from "react";
import FormGroup from "../FormGroup";
import {
  QuestionaireSecondaryPassedThroughProps,
  QuestionType,
} from "../types";

const QuestionsSecondary = (props: QuestionaireSecondaryPassedThroughProps) => {
  const { questionsSecondary, setQuestionsSecondary } = props;

  return (
    <>
      {questionsSecondary &&
        questionsSecondary.map(
          ({
            displayValue,
            value,
            id,
            hint,
            required,
            type,
            placeholder,
            invalid,
            feedbackInvalid,
          }: QuestionType) => {
            return (
              <FormGroup
                key={id}
                value={value}
                id={id}
                displayValue={displayValue}
                hint={hint}
                required={required}
                type={type}
                placeholder={placeholder}
                feedbackInvalid={feedbackInvalid}
                invalid={invalid}
                onChangeEvent={(evnt: any) => {
                  const { value } = evnt.target;
                  const questionsWithValue: QuestionType[] =
                    questionsSecondary.map((question) => {
                      const { id: qId } = question;
                      const sameQuestion = qId === id;
                      if (sameQuestion) {
                        question.value = value;
                      }
                      return question;
                    });
                  setQuestionsSecondary(questionsWithValue);
                }}
              />
            );
          }
        )}
    </>
  );
};

export default QuestionsSecondary;
